import { User } from "../../interfaces/User";
import Avatar from "../Avatar";
import { Card, UserInfo, UserTextData } from "./styles";
import userImg from "../../assets/imgs/user.png";
import { useRecoilState } from "recoil";
import userState from "../../states/userState";
import { useMemo } from "react";

interface RegisterPeopleUserCardProps {
  user: User;
  onClick: () => void;
  selected: boolean;
  isDependent?: boolean;
}

export default function RegisterPeopleUserCard({
  user,
  onClick,
  selected,
  isDependent,
}: RegisterPeopleUserCardProps) {
  const [loggedUser] = useRecoilState(userState);

  const towerLabel =
    loggedUser?.residential?.labelType !== "house" ? "Torre" : "Rua";
  const aptLabel =
    loggedUser?.residential?.labelType === "house"
      ? "Casa"
      : loggedUser?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apt";
  const residentLabel =
    loggedUser?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";

  const classes = [selected ? "selected" : ""].join(" ");

  const fullName = `${user.name ?? ""} ${user.surname ?? ""}`;
  const photo = user.imageUUID
    ? "https://guugcall-prod.s3-sa-east-1.amazonaws.com/users_images/" +
      user.imageUUID +
      ".jpg"
    : userImg;

  const residentTypeLabels = useMemo(
    () => ({
      owner: "Proprietário",
      tenant:
        loggedUser?.residential?.labelType === "commercial"
          ? "Locatário"
          : "Inquilino",
      employee: "Funcionário",
    }),
    [loggedUser?.residential?.labelType]
  );

  return (
    <Card className={classes} onClick={onClick}>
      <UserInfo>
        <Avatar
          src={photo}
          alt={`Foto d${
            residentLabel === "Morador" ? "o" : "a"
          } ${residentLabel.toLocaleLowerCase()}`}
          size="xs"
        />
        <UserTextData>
          <h4>{fullName}</h4>
          {user.company && (
            <p>
              Empresa: <span>{user.company}</span>
            </p>
          )}
          {user.residentType && (
            <p>
              <span>{residentTypeLabels[user.residentType]}</span>
            </p>
          )}
          {user.condominium?.name &&
            user.condominium?.name !== user.tower?.name && (
              <p>
                Cond:{" "}
                <span>
                  {user.condominium.name.length > 20
                    ? user.condominium.name.replace(/(.{20}).+/, "$1...")
                    : user.condominium.name}
                </span>
              </p>
            )}
          {user.tower?.name && (
            <p>
              {towerLabel}:{" "}
              <span>
                {user.tower.name.length > 20
                  ? user.tower.name.replace(/(.{20}).+/, "$1...")
                  : user.tower.name}
              </span>
            </p>
          )}
          {user.apartment?.name && (
            <p>
              {aptLabel}:{" "}
              <span>
                {user.apartment.name.length > 20
                  ? user.apartment.name.replace(/(.{20}).+/, "$1...")
                  : user.apartment.name}
              </span>
            </p>
          )}
        </UserTextData>
      </UserInfo>
    </Card>
  );
}
