import { Container } from "./styles";

interface ChatMessageProps {
  isReceivedMessage: boolean;
  messageContent: string;
  time: string;
  username?: string;
}

export default function ChatMessage({
  isReceivedMessage,
  messageContent,
  time,
  username,
}: ChatMessageProps) {
  const messageTypeClass = isReceivedMessage ? "received" : "sent";

  return (
    <Container className={messageTypeClass}>
      <p>{messageContent}</p>
      <div>
        {username && (
          <span>
            ~{" "}
            {username.length > 20
              ? username.substring(0, 21) + "..."
              : username}
          </span>
        )}
        <time dateTime={time}>{time}</time>
      </div>
    </Container>
  );
}
