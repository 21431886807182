import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useRecoilState } from "recoil";
import { Apartment } from "../../interfaces/Apartment";
import { Condominium } from "../../interfaces/Condominium";
import { Tower } from "../../interfaces/Tower";
import { Permission, User } from "../../interfaces/User";
import { api } from "../../services/api";
import userState from "../../states/userState";
import { notify, ToastType } from "../../utils/toast";
import Button from "../Button";
import ControlledInput from "../ControlledInput";
import LoadingMessage from "../LoadingMessage";
import Modal from "../Modal";
import RadioOption from "../RadioOption";
import Select from "../Select";
import UserCard from "../UserCard";
import {
  SearchResidentForm,
  SearchTypeOptions,
  SelectsContainer,
  InputContainer,
  ButtonContainer,
  ResidentCardsContainer,
  DatalistLabel,
} from "./styles";
import Datalist, { Item } from "../Datalist";
import Company from "../../interfaces/Company";
import getCompany from "../../utils/getCompany";

interface IntercomComponentProps {
  forceRefreshState?: [boolean, (value: boolean) => void];
  hasAnotherButton?: boolean;
  onlyResidents?: boolean;
  buttonLabel?: string;
  buttonAction?: () => void;
  Card?: ({
    user,
    onClick,
  }: {
    user: User;
    onClick: () => void;
  }) => JSX.Element;
  cardButtonAction?: (user: User) => void;
}

type Resident = User & { isDependent?: boolean };

export default function IntercomComponent({
  forceRefreshState = [false, () => {}],
  hasAnotherButton = false,
  onlyResidents = false,
  buttonAction = () => {},
  buttonLabel = "",
  Card = undefined,
  cardButtonAction = () => {},
}: IntercomComponentProps) {
  const [user] = useRecoilState(userState);

  const towerLabel = user?.residential?.labelType !== "house" ? "Torre" : "Rua";
  const apartmentLabel =
    user?.residential?.labelType === "house"
      ? "Casa"
      : user?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apartamento";
  const residentLabel =
    user?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";

  const [residents, setResidents] = useState<Resident[]>([]);
  const [isResidentsFetching, setIsResidentsFetching] =
    useState<boolean>(false);
  const [isLoadingSecondButtonAction, setIsLoadingSecondButtonAction] =
    useState(false);

  const [condominia, setCondominia] = useState<Condominium[]>([]);
  const [towers, setTowers] = useState<Tower[]>([]);
  const [apartments, setApartments] = useState<Apartment[]>([]);

  const [searchedCompany, setSearchedCompany] = useState<string>("");
  const [searchedCompanyApartmentId, setSearchedCompanyApartmentId] =
    useState(0);
  const [datalistCompanies, setDatalistCompanies] = useState<Item[]>([]);

  const [searchedName, setSearchedName] = useState<string>("");
  const [condominiumId, setCondominiumId] = useState<string>(
    String(condominia[0]?.id ?? "")
  );
  const [towerId, setTowerId] = useState<string>(String(towers[0]?.id ?? ""));
  const [apartmentId, setApartmentId] = useState<string>(
    String(apartments[0]?.id ?? "")
  );

  const [searchOption, setSearchOption] = useState<
    "apartment" | "name" | "company"
  >("apartment");
  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(true);

  const [forceRefresh, setForceRefresh] = forceRefreshState;

  const fetchUsers = useCallback(
    async (endpoint: string) => {
      if (user)
        try {
          const response = await api.get(endpoint);
          const dependents = [];

          if (searchOption === "name") {
            const response = await api.get(
              `/dependentUser/searchByName/${searchedName}/${
                user.residentialId
              }/${user.condominiumId ?? ""}`
            );
            dependents.push(
              ...response.data.map((item: any) => ({
                ...item,
                isDependent: true,
              }))
            );
          }

          const users =
            !onlyResidents && response.data.dependents
              ? [
                  ...response.data.users,
                  ...response.data.dependents.map((item: any) => ({
                    ...item,
                    isDependent: true,
                  })),
                ]
              : response.data.users.filter(
                  (user: User) => user.permission === "resident"
                );

          users.push(...dependents);

          if (users.length > 0) {
            if (user.residential.labelType === "commercial") {
              const company = await getCompany(
                users[0].residentialId,
                users[0].condominiumId as number,
                users[0].towerId as number,
                users[0].apartmentId as number
              );
              if (company)
                setResidents(users.map((user: any) => ({ ...user, company })));
              else setResidents(users);
            } else setResidents(users);
          } else {
            notify(
              "Não encontramos nenhum resultado para a busca!",
              ToastType.warn
            );
          }
        } catch (e) {
          console.log(e);
          notify(
            "Ocorreu um erro na busca. Por favor tente novamente.",
            ToastType.error
          );
        }
    },
    [onlyResidents, searchOption, searchedName, user]
  );
  const fetchInitialSelectOptions = useCallback(
    async (permission: Permission) => {
      const endpoint =
        permission === "generalPorter"
          ? `residential/getCondominia/${user.residentialId}`
          : `condominium/getTowers/${user.condominiumId}`;

      const response = await api.get(endpoint);

      if (permission === "generalPorter") {
        const condominia: Condominium[] = response.data.condominia;
        const condominiumOptions = condominia
          .map((condominium) => ({
            id: condominium.id,
            name: condominium.name,
          }))
          .filter((condominium) => condominium.name !== "Interfone");

        return setCondominia(condominiumOptions);
      } else {
        const towers: Tower[] = response.data.towers;
        const towerOptions = towers

          .map((tower) => ({
            id: tower.id,
            name: tower.name,
          }))
          .filter((condominium) => condominium.name !== "Interfone");

        return setTowers(towerOptions);
      }
    },
    [user.condominiumId, user.residentialId]
  );
  const fetchTowers = useCallback(async (condominiumId: number | string) => {
    setTowerId("");
    setApartmentId("");
    setTowers([]);
    setApartments([]);

    const response = await api.get(`condominium/getTowers/${condominiumId}`);
    const towers: Tower[] = response.data.towers;

    const towerOptions = towers

      .map((tower) => ({
        id: tower.id,
        name: tower.name,
      }))
      .filter((condominium) => condominium.name !== "Interfone");

    return setTowers(towerOptions);
  }, []);
  const fetchApartments = useCallback(async (towerId: number | string) => {
    setApartmentId("");
    setApartments([]);

    const response = await api.get(`tower/getApartmentsWithUsers/${towerId}`);
    const apartments: Apartment[] = response.data.apartments.filter(
      (item: any) => item.name !== "Interfone"
    );

    const apartmentsOptions = apartments
      .map((apartment) => ({
        id: apartment.id,
        name: apartment.name,
      }))
      .filter((condominium) => condominium.name !== "Interfone");

    return setApartments(apartmentsOptions);
  }, []);

  const handleSearch = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        setIsResidentsFetching(true);

        setResidents([]);

        if (searchedName || apartmentId || searchedCompanyApartmentId) {
          const endpoint =
            searchOption === "name"
              ? `/user/searchByName/${searchedName}/${user.residentialId}/${
                  user.condominiumId ?? ""
                }`
              : searchOption === "company"
              ? `/apartment/usersAndDependents/${searchedCompanyApartmentId}?phone=true`
              : `/apartment/usersAndDependents/${apartmentId}?phone=true`;

          await fetchUsers(endpoint);
        }
      } catch {
        notify("Ops... Ocorreu um erro durante a busca!", ToastType.error);
      } finally {
        setIsResidentsFetching(false);
      }
    },
    [
      apartmentId,
      fetchUsers,
      searchOption,
      searchedCompanyApartmentId,
      searchedName,
      user.condominiumId,
      user.residentialId,
    ]
  );

  useEffect(() => {
    if (forceRefresh) {
      const endpoint =
        searchOption === "name"
          ? `user/searchByName/${searchedName}/${user.residentialId}`
          : searchOption === "company"
          ? `/apartment/usersAndDependents/${searchedCompanyApartmentId}`
          : `/apartment/usersAndDependents/${apartmentId}`;
      fetchUsers(endpoint);

      setForceRefresh(false);
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (searchOption === "apartment") {
      setSearchedName("");
      setSearchedCompany("");
    } else if (searchOption === "company") {
      if (user.permission === "generalPorter") {
        if (
          condominia.length === 1 &&
          towers.length === 1 &&
          condominia[0].name === towers[0].name
        )
          setApartmentId("");
        else setCondominiumId("");
      } else {
        if (towers.length === 1) setApartmentId("");
        else setTowerId("");
      }
      setSearchedName("");
    } else {
      if (user.permission === "generalPorter") {
        if (
          condominia.length === 1 &&
          towers.length === 1 &&
          condominia[0].name === towers[0].name
        )
          setApartmentId("");
        else setCondominiumId("");
      } else {
        if (towers.length === 1) setApartmentId("");
        else setTowerId("");
      }
      setSearchedCompany("");
    }
  }, [searchOption]);

  useEffect(() => {
    if (
      (searchOption === "apartment" && !apartmentId) ||
      (searchOption === "name" && !searchedName)
    )
      setIsSearchDisabled(true);
    else setIsSearchDisabled(false);
  }, [apartmentId, searchOption, searchedName]);

  useEffect(() => {
    const permission =
      user.permission === "generalPorter" || "localPorter"
        ? user.permission
        : "";

    if (permission) fetchInitialSelectOptions(user.permission);
  }, [fetchInitialSelectOptions, user]);

  useEffect(() => {
    if (condominiumId) fetchTowers(condominiumId);
    else {
      setTowerId("");
      setApartmentId("");
      setTowers([]);
      setApartments([]);
    }
  }, [condominiumId, fetchTowers]);
  useEffect(() => {
    if (towerId) fetchApartments(towerId);
    else {
      setApartmentId("");
      setApartments([]);
    }
  }, [fetchApartments, towerId]);

  useEffect(() => {
    if (towers.length === 1) setTowerId(String(towers[0].id));
  }, [towers]);

  useEffect(() => {
    const residentialId = user.residentialId ?? 0;
    const condominiumId = user.condominiumId ?? 0;

    if (searchedCompany)
      api
        .get(
          `/companies/all/10/0/${residentialId}/${condominiumId}/0/0/${searchedCompany}`
        )
        .then((res) => res.data)
        .then((data) => {
          const companies: Company[] = data.rows;

          setDatalistCompanies(
            companies.map(
              (company): Item => ({
                key: String(company.apartmentId),
                value:
                  company.name +
                  " - " +
                  "CNPJ: " +
                  company.cnpj +
                  " - " +
                  "Sala: " +
                  company.apartment.name,
              })
            )
          );
        });
    else setDatalistCompanies([]);
  }, [searchedCompany]);

  return (
    <>
      <SearchResidentForm onSubmit={handleSearch}>
        <SearchTypeOptions>
          <div style={{ marginRight: "1.5rem" }}>
            <RadioOption
              onClick={() => setSearchOption("apartment")}
              id="apartment-search"
              defaultChecked={true}
              selected={searchOption === "apartment"}
              label={`Pesquisar por ${apartmentLabel.toLocaleLowerCase()}`}
            />
          </div>
          <div style={{ marginRight: "1.5rem" }}>
            <RadioOption
              onClick={() => setSearchOption("name")}
              id="name-search"
              defaultChecked={false}
              selected={searchOption === "name"}
              label="Pesquisar por nome"
            />
          </div>
          {user.residential && user.residential.labelType === "commercial" && (
            <RadioOption
              onClick={() => setSearchOption("company")}
              id="company-search"
              defaultChecked={false}
              selected={searchOption === "company"}
              label="Pesquisar por empresa"
            />
          )}
        </SearchTypeOptions>

        <SelectsContainer
          className={searchOption !== "apartment" ? "hidden" : ""}
        >
          {user.permission === "generalPorter" && (
            <Select
              size="lg"
              id="Condominium"
              label="Condomínio"
              options={condominia}
              value={condominiumId}
              onChange={(e) => {
                setCondominiumId(e.target.value);
              }}
            />
          )}
          <Select
            size="lg"
            id="Tower"
            label={towerLabel}
            options={towers}
            value={towerId}
            onChange={(e) => {
              setTowerId(e.target.value);
            }}
          />
          <Select
            size="lg"
            id="Apartment"
            label={apartmentLabel + "s"}
            options={apartments}
            value={apartmentId}
            onChange={(e) => {
              setApartmentId(e.target.value);
            }}
          />
        </SelectsContainer>

        <InputContainer className={searchOption !== "name" ? "hidden" : ""}>
          <ControlledInput
            size="xl"
            label={`Nome d${
              residentLabel === "Morador" ? "o" : "a"
            } ${residentLabel.toLocaleLowerCase()}`}
            id="nome"
            value={searchedName}
            onChange={(e) => setSearchedName(e.target.value)}
          />
        </InputContainer>

        {user.residential && user.residential.labelType === "commercial" && (
          <InputContainer
            className={searchOption !== "company" ? "hidden" : ""}
          >
            <DatalistLabel>Empresa</DatalistLabel>
            <Datalist
              value={searchedCompany}
              onChange={async (event) => {
                setSearchedCompanyApartmentId(0);
                const { value } = event.target;

                setSearchedCompany(value);
              }}
              selectValue={(value) => {
                setSearchedCompany(value);
                setDatalistCompanies([]);
              }}
              selectKey={(key) => {
                setSearchedCompanyApartmentId(+key);
              }}
              list={datalistCompanies}
            />
          </InputContainer>
        )}

        <ButtonContainer>
          <Button
            disabled={
              isSearchDisabled ||
              (searchOption === "company" && searchedCompanyApartmentId === 0)
            }
            loading={isResidentsFetching}
            type="submit"
            title="Buscar"
            size="lg"
          />
          {hasAnotherButton && (
            <Button
              title={buttonLabel}
              onClick={() => {
                setIsLoadingSecondButtonAction(true);

                try {
                  buttonAction();
                } catch {
                } finally {
                  setIsLoadingSecondButtonAction(false);
                }
              }}
              size="lg"
              loading={isLoadingSecondButtonAction}
            />
          )}
        </ButtonContainer>
      </SearchResidentForm>
      <ResidentCardsContainer>
        {(isResidentsFetching && (
          <LoadingMessage parentType="not-flex-parent" />
        )) ||
          (Card &&
            residents.map((resident) => (
              <Card
                key={`card-${resident.id}`}
                user={resident}
                onClick={() => {
                  cardButtonAction(resident);
                }}
              />
            ))) ||
          (residents.length > 0 &&
            residents.map((resident) => (
              <UserCard
                isDependent={resident.isDependent}
                key={`user-card-${resident.id}`}
                user={resident}
              />
            )))}
      </ResidentCardsContainer>
    </>
  );
}
