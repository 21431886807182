import moment from "moment";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import phoneIcon from "../../assets/imgs/phone.png";
import sendIcon from "../../assets/imgs/send.png";
import MessagesHistoryCard from "../../components/MessagesHistoryCard";
import ChatMessage from "../../components/ChatMessage";
import ControlledInput from "../../components/ControlledInput";
import DateBox from "../../components/DateBox";
import Layout from "../../components/Layout";
import NotFoundMessage from "../../components/NotFoundMessage";
import PngIcon from "../../components/PngIcon";
import { Message } from "../../interfaces/Message";
import { Permission, User } from "../../interfaces/User";
import { api } from "../../services/api";
import { currentChatMessagesState } from "../../states/currentChatMessagesState";
import selectedUserToChatState, {
  SelectedUserToChat,
} from "../../states/selectedUserToChatState";
import userState from "../../states/userState";
import useStartCall from "../../utils/startCall";
import { notify, ToastType } from "../../utils/toast";
import {
  ChatBody,
  ChatContainer,
  ChatFoot,
  ChatHead,
  Container,
  ScrollLoadingContainer,
  UserCards,
  UserCardsContainer,
} from "./styles";
import setDateBoxes from "../../utils/setDateBoxes";
import LoadingMessage from "../../components/LoadingMessage";
import notificationsState from "../../states/notificationsState";
import { Residential } from "../../interfaces/Residential";
import { Condominium } from "../../interfaces/Condominium";
import { Tower } from "../../interfaces/Tower";
import { Apartment } from "../../interfaces/Apartment";
import { BeatLoader, ClipLoader } from "react-spinners";
import getCompany from "../../utils/getCompany";

type Structure = { name: string; id: number };

type MessageUser = {
  apartment: Structure | null;
  condominium: Structure | null;
  id: number;
  imageUUID: string;
  name: string;
  permission: string;
  phone: string;
  residential: Structure | null;
  shouldCallVoip: boolean;
  surname: string;
  tower: Structure | null;
};

type PorterMessage = {
  content: string;
  createdAt: string;
  id: number;
  idUserFrom: number;
  idUserTo: number;
  status: string;
  time: string;
  updatedAt: string;
  userFrom: MessageUser;
  userTo: MessageUser;
};

type MessageNotification = {
  apartment: string;
  condominium: string;
  imageUUID: string;
  messageId: string;
  name: string;
  permission: string;
  residential: string;
  shouldCallVoip: string;
  text: string;
  tower: string;
  userId_to: string;
  user_id: string;
  user_name: string;
  user_phone: string;
  imageUUID_to?: string;
  name_to?: string;
  apartment_to?: string;
  tower_to?: string;
  condominium_to?: string;
  residential_to?: string;
  permission_to?: string;
  user_phone_to?: string;
};

function Messages() {
  const [user] = useRecoilState(userState);

  const startCall = useStartCall();

  const [selectedUserToChat, setSelectedUserToChat] = useRecoilState(
    selectedUserToChatState
  );
  const [currentChatMessages, setCurrentChatMessages] = useRecoilState(
    currentChatMessagesState
  );
  const [messagesHistory, setMessagesHistory] = useState<
    Array<User | SelectedUserToChat>
  >([]);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const [isChatFetching, setIsChatFetching] = useState<boolean>(false);

  const [isHistoryFetched, setIsHistoryFetched] = useState<boolean>(false);
  const [isHistoryFetching, setIsHistoryFetching] = useState<boolean>(false);
  const [isScrollLoading, setIsScrollLoading] = useState(false);

  const [search, setSearch] = useState<string>("");

  const [isAllChatsLoaded, setIsAllChatsLoaded] = useState(false);

  const [messagesHistoryCards, setMessagesHistoryCards] = useState<
    Array<JSX.Element | boolean>
  >([]);

  const handleSelectChat = useCallback(
    async (selectedUser: User | SelectedUserToChat) => {
      setSelectedUserToChat(selectedUser);

      if (selectedUser.permission?.includes("Porter")) {
        const response = await api.get(
          `/messages/${user.id}/${selectedUser.id}/0`
        );

        const messages: Message[] = response.data.messages;

        if (messages.length) setCurrentChatMessages(setDateBoxes(messages));
        else setCurrentChatMessages([]);
      } else {
        const response = await api.get(
          `/messages/porter/${selectedUser.id}/${user.residentialId}`
        );

        const messages = response.data.map((item: any) => ({
          ...item,
          dateBox: moment(item.createdAt).format("DD/MM/YYYY"),
        }));

        if (messages.length) setCurrentChatMessages(setDateBoxes(messages));
        else setCurrentChatMessages([]);
      }

      setIsChatFetching(false);
    },
    [setCurrentChatMessages, setSelectedUserToChat, user.id, user.residentialId]
  );

  const fetchMessagesHistory = useCallback(
    async (offset: number, residentialId: number, userId: number) => {
      if (user.id) {
        const response = await api.get(
          `/messages/lastChatPorter/20/${offset}/${residentialId}/${userId}`
        );
        const porterMessages: PorterMessage[] = response.data;

        if (porterMessages.length === 0) {
          setIsAllChatsLoaded(true);
          return;
        }

        const isCommercialResidential =
          user?.residential?.labelType === "commercial";

        const allLastMessages: (User | SelectedUserToChat)[] = [];

        if (isCommercialResidential) {
          for (let i = 0; i < porterMessages.length; i++) {
            let chatUser: MessageUser;

            if (porterMessages[i].idUserFrom === user.id)
              chatUser = porterMessages[i].userTo;
            else if (porterMessages[i].idUserTo === user.id)
              chatUser = porterMessages[i].userFrom;
            else if (porterMessages[i].userFrom.permission.includes("Porter"))
              chatUser = porterMessages[i].userTo;
            else chatUser = porterMessages[i].userFrom;

            const company = await getCompany(
              chatUser.residential?.id as number,
              chatUser.condominium?.id as number,
              chatUser.tower?.id as number,
              chatUser.apartment?.id as number
            );

            const item = {
              id: chatUser.id,
              name: chatUser.name,
              surname: chatUser.surname,
              phone: chatUser.phone,
              content: porterMessages[i].content,
              company,
              //lastMessageUnseen: boolean,
              residential: chatUser.residential as Residential,
              condominium: chatUser.condominium as Condominium,
              tower: chatUser.tower as Tower,
              apartment: chatUser.apartment as Apartment,
              apartmentId: chatUser.apartment?.id,
              towerId: chatUser.tower?.id,
              condominiumId: chatUser.condominium?.id,
              residentialId: chatUser.residential?.id as number,
              imageUUID: chatUser.imageUUID,
              permission: chatUser.permission as Permission,
              shouldCallVoip: chatUser.shouldCallVoip,
              createdAt: porterMessages[i].createdAt,
              updatedAt: porterMessages[i].updatedAt,
            };

            allLastMessages.push(item);
          }
        } else
          allLastMessages.push(
            ...porterMessages.map((message): User => {
              let chatUser: MessageUser;

              if (message.idUserFrom === user.id) chatUser = message.userTo;
              else if (message.idUserTo === user.id)
                chatUser = message.userFrom;
              else if (message.userFrom.permission.includes("Porter"))
                chatUser = message.userTo;
              else chatUser = message.userFrom;

              return {
                id: chatUser.id,
                name: chatUser.name,
                surname: chatUser.surname,
                phone: chatUser.phone,
                content: message.content,
                //lastMessageUnseen: boolean,
                residential: chatUser.residential as Residential,
                condominium: chatUser.condominium as Condominium,
                tower: chatUser.tower as Tower,
                apartment: chatUser.apartment as Apartment,
                apartmentId: chatUser.apartment?.id,
                towerId: chatUser.tower?.id,
                condominiumId: chatUser.condominium?.id,
                residentialId: chatUser.residential?.id as number,
                imageUUID: chatUser.imageUUID,
                permission: chatUser.permission as Permission,
                shouldCallVoip: chatUser.shouldCallVoip,
                createdAt: message.createdAt,
                updatedAt: message.updatedAt,
              };
            })
          );

        const userExists = allLastMessages.some(
          (fetched) => fetched.id === selectedUserToChat.id
        );

        if (Object.values(selectedUserToChat).length > 0 && !userExists)
          setMessagesHistory([selectedUserToChat, ...allLastMessages]);
        else
          setMessagesHistory((messages) => [...messages, ...allLastMessages]);
      }
    },
    [user.id, user?.residential?.labelType, selectedUserToChat]
  );

  const [sendingMessage, setSendingMessage] = useState<string>("");
  const [sendMessageEnabled, setSendMessageEnabled] = useState<boolean>(true);
  const handleSendMessage = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      const sendingMessageAux = sendingMessage;
      const currentChatMessagesAux = [...currentChatMessages];

      try {
        setSendingMessage("");

        const createdAt = moment().format();
        const currentTime = moment().format("HH:mm");

        const sentMessage = {
          idUserFrom: user.id,
          idUserTo: selectedUserToChat.id,
          content: sendingMessageAux,
          createdAt,
          time: currentTime,
        };

        setCurrentChatMessages([...currentChatMessages, sentMessage]);

        if (sendingMessageAux) {
          await api.post("/messages/send", {
            text: sendingMessageAux,
            to: selectedUserToChat.id,
            user_name: user.name,
            user_id: user.id,
            user_phone: user.phone,
          });
        }
      } catch (e) {
        notify(
          "Ops... ocorreu um erro ao tentar enviar sua mensagem!",
          ToastType.error
        );

        setSendingMessage(sendingMessageAux);
        setCurrentChatMessages(currentChatMessagesAux);
      }
    },
    [
      selectedUserToChat.id,
      currentChatMessages,
      sendingMessage,
      setCurrentChatMessages,
      user.id,
      user.name,
      user.phone,
    ]
  );

  const messageNotificationCallback: (
    this: ServiceWorkerContainer,
    ev: MessageEvent<any>
  ) => any = useCallback(
    (event) => {
      if (event.data.data.text) {
        const notificationData: MessageNotification = event.data.data;
        const jsonUser = localStorage.getItem("guugWebPorter.user");
        let localStorageUser: User | undefined;
        if (jsonUser) localStorageUser = JSON.parse(jsonUser);
        //Notification sended from same user
        if (notificationData.user_id == String(localStorageUser?.id)) return;

        let newChat: any;
        if (
          notificationData.permission.includes("Porter") &&
          notificationData.userId_to != String(localStorageUser?.id)
        )
          newChat = {
            id: +notificationData.userId_to,
            name: notificationData.name_to,
            phone: notificationData.user_phone_to,
            residential: {
              name: notificationData.residential_to,
            } as Residential,
            condominium: {
              name: notificationData.condominium_to,
            } as Condominium,
            tower: { name: notificationData.tower_to } as Tower,
            apartment: { name: notificationData.apartment_to } as Apartment,
            residentialId: localStorageUser?.residentialId,
            shouldCallVoip: false,
            content: notificationData.text,
            imageUUID: notificationData.imageUUID_to,
            permission: notificationData.permission_to as Permission,
          };
        else
          newChat = {
            id: +notificationData.user_id,
            name: notificationData.name,
            phone: JSON.parse(notificationData.user_phone)[0],
            residential: { name: notificationData.residential } as Residential,
            condominium: { name: notificationData.condominium } as Condominium,
            tower: { name: notificationData.tower } as Tower,
            apartment: { name: notificationData.apartment } as Apartment,
            residentialId: localStorageUser?.residentialId,
            shouldCallVoip: Boolean(notificationData.shouldCallVoip),
            content: notificationData.text,
            imageUUID: notificationData.imageUUID,
            permission: notificationData.permission as Permission,
          };

        setMessagesHistory((messages) => {
          const id = messages.findIndex(
            (message) => message.id === +newChat.id
          );

          const messagesAux = [...messages];

          if (id !== -1) messagesAux.splice(id, 1);

          messagesAux.unshift(newChat);
          return messagesAux;
        });
      }
    },
    [setMessagesHistory]
  );

  useEffect(() => {
    setIsHistoryFetching(true);

    if (user.id && user.residentialId) {
      fetchMessagesHistory(0, user.residentialId, user.id).then(() => {
        setIsHistoryFetched(true);
        setIsHistoryFetching(false);
      });
    }
  }, [user.id, user.residentialId]);

  useEffect(() => {
    const chatBody = document.getElementById("chat-body") as HTMLDivElement;
    const elemHeight = chatBody.scrollHeight;

    chatBody.scrollTo(0, elemHeight);
  }, [currentChatMessages]);

  useEffect(() => {
    const cards = messagesHistory
      .map((user, i) => {
        return (
          ((user.name &&
            user.name.toLowerCase().includes(search.toLowerCase())) ||
            search === "") && (
            <MessagesHistoryCard
              size="lg"
              selected={user.id === selectedUserToChat.id}
              key={i}
              onClick={() => {
                setIsChatFetching(true);

                handleSelectChat(user);
              }}
              user={user}
            />
          )
        );
      })
      .filter((card) => card);

    setMessagesHistoryCards(cards);
  }, [selectedUserToChat.id, handleSelectChat, messagesHistory, search]);

  useEffect(() => {
    handleSelectChat(selectedUserToChat);

    const someMessageNotificationFromSelectedUser = [...notifications].some(
      (item) =>
        item.type === "MessageNotification" &&
        parseInt(item.data.user_id) === selectedUserToChat.id
    );

    if (someMessageNotificationFromSelectedUser) {
      const newNotifications = [...notifications].filter(
        (item) =>
          item.type !== "PhotoValidationNotification" &&
          (item.type === "OpenDoorNotification" ||
            item.type === "CallNotification" ||
            item.type === "AccessNotification" ||
            item.type === "Default" ||
            item.type === "EmergencyNotification" ||
            item.type === "VehicleNotification" ||
            parseInt(item.data.user_id) !== selectedUserToChat.id)
      );

      setNotifications(newNotifications);
      localStorage.setItem(
        `guugWebPorter.notifications.user${user.id}`,
        JSON.stringify(newNotifications)
      );
    }
  }, [
    handleSelectChat,
    notifications,
    selectedUserToChat,
    setNotifications,
    user.id,
  ]);

  useEffect(() => {
    navigator.serviceWorker.addEventListener(
      "message",
      messageNotificationCallback
    );
  }, []);

  return (
    <Layout>
      <Container>
        {(isHistoryFetching && <LoadingMessage text="Carregando..." />) || (
          <UserCardsContainer>
            <ControlledInput
              label=""
              id=""
              placeholder="Pesquisar por nome..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <UserCards
              onScroll={
                !isAllChatsLoaded
                  ? async (e) => {
                      const elem = e.currentTarget;
                      const { scrollHeight, scrollTop, clientHeight } = elem;
                      const heightPosition = scrollTop + clientHeight;

                      if (scrollHeight - heightPosition <= 4) {
                        setIsScrollLoading(true);

                        await fetchMessagesHistory(
                          messagesHistoryCards.length,
                          user.residentialId,
                          user.id
                        );

                        setIsScrollLoading(false);
                      }
                    }
                  : undefined
              }
            >
              {messagesHistoryCards.length > 0 ? (
                messagesHistoryCards
              ) : isHistoryFetched ? (
                <NotFoundMessage message="Nenhum usuário encontrado." />
              ) : (
                ""
              )}
            </UserCards>
            {isScrollLoading && (
              <ScrollLoadingContainer>
                <BeatLoader color="#ff4f00" />
              </ScrollLoadingContainer>
            )}
          </UserCardsContainer>
        )}
        <ChatContainer>
          <ChatHead>
            <h4>{`${selectedUserToChat.name ?? ""} ${
              selectedUserToChat.surname ?? ""
            }`}</h4>
            <button
              disabled={!selectedUserToChat.id}
              onClick={async () => {
                try {
                  if (
                    selectedUserToChat.phone !== "" &&
                    selectedUserToChat.permission
                  ) {
                    await startCall(selectedUserToChat as User);
                  }
                } catch {
                  notify("Ops... Erro ao iniciar chamada!", ToastType.error);
                }
              }}
            >
              <PngIcon icon={phoneIcon} />
            </button>
          </ChatHead>
          <ChatBody id="chat-body">
            {(isChatFetching && (
              <LoadingMessage
                parentType="not-flex-parent"
                text="Carregando..."
              />
            )) ||
              (currentChatMessages.length > 0 &&
                currentChatMessages.map((message, i) => {
                  const time = moment(message.createdAt).format("HH:mm");

                  return (
                    <React.Fragment
                      key={`${message.idUserFrom}${message.idUserTo}${i}`}
                    >
                      {message.dateBox && <DateBox time={message.dateBox} />}
                      <ChatMessage
                        isReceivedMessage={user.id !== message.idUserFrom}
                        messageContent={message.content}
                        time={time}
                        username={
                          user.id !== message.idUserFrom && message.userFrom
                            ? message.userFrom.name +
                              (message.userFrom.surname
                                ? " " + message.userFrom.surname
                                : "")
                            : undefined
                        }
                      />
                    </React.Fragment>
                  );
                }))}
          </ChatBody>
          <ChatFoot>
            <form onSubmit={handleSendMessage}>
              <ControlledInput
                label=""
                id=""
                disabled={!selectedUserToChat.id}
                value={sendingMessage}
                onChange={(e) => {
                  const input = e.target.value;

                  if (input) {
                    setSendMessageEnabled(false);
                  } else setSendMessageEnabled(true);

                  setSendingMessage(input);
                }}
                placeholder="Digite sua mensagem aqui..."
              />
              <button disabled={sendMessageEnabled} type="submit">
                <PngIcon icon={sendIcon} />
              </button>
            </form>
          </ChatFoot>
        </ChatContainer>
      </Container>
    </Layout>
  );
}

export default Messages;
