import { useRecoilState } from "recoil";
import noPhoto from "../../assets/imgs/user.png";
import { SelectedUserToChat } from "../../states/selectedUserToChatState";
import userState from "../../states/userState";
import Avatar from "../Avatar";
import { Container, Message, UserInfo, UserTextData } from "./styles";
import { useEffect } from "react";

interface MessagesHistoryCardProps {
  size: string;
  onClick: () => void;
  user: SelectedUserToChat;
  selected: boolean;
  company?: string;
}

const roles = {
  localPorter: "Porteiro local",
  generalPorter: "Porteiro geral",
  resident: "",
  janitor: "Zelador",
  aptManager: "Síndico",
};

type ResidentTypeLabels = "owner" | "tenant" | "employee";

const residentTypeLabels = {
  owner: "Proprietário",
  tenant: "",
  employee: "Funcionário",
};

export default function MessagesHistoryCard({
  size,
  onClick,
  user,
  selected,
  company,
}: MessagesHistoryCardProps) {
  const [loggedUser] = useRecoilState(userState);

  const towerLabel =
    loggedUser?.residential?.labelType !== "house" ? "Torre" : "Rua";
  const aptLabel =
    loggedUser?.residential?.labelType === "house"
      ? "Casa"
      : loggedUser?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apt";
  const residentLabel =
    loggedUser?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";
  const tenantLabel =
    loggedUser?.residential?.labelType === "commercial"
      ? "Locatário"
      : "Inquilino";

  const classes = [selected ? "selected" : ""];
  const fullName = `${user.name ?? ""} ${user.surname ?? ""}`;
  const userPhoto = user.imageUUID
    ? "https://guugcall-prod.s3-sa-east-1.amazonaws.com/users_images/" +
      user.imageUUID +
      ".jpg"
    : noPhoto;

  useEffect(() => {
    roles.resident = residentLabel;
    residentTypeLabels.tenant = tenantLabel;
  }, [residentLabel, tenantLabel]);

  return (
    <Container className={classes.join(" ")} onClick={onClick}>
      <UserInfo>
        <Avatar
          src={userPhoto}
          alt={`Foto d${
            residentLabel === "Morador" ? "o" : "a"
          } ${residentLabel.toLocaleLowerCase()}`}
          size="sm"
        />
        <UserTextData>
          <h4>{fullName}</h4>
          {user.permission && (
            <p>
              <span>
                {roles[user.permission] +
                  (user.residentType
                    ? ` (${
                        residentTypeLabels[
                          user.residentType as ResidentTypeLabels
                        ]
                      })`
                    : "")}
              </span>
            </p>
          )}
          {user.company && (
            <p>
              Empresa: <span>{user.company}</span>
            </p>
          )}
          {user.condominium?.name &&
            user.condominium?.name !== user.tower?.name && (
              <p>
                Cond: <span>{user.condominium.name}</span>
              </p>
            )}
          {user.tower?.name && (
            <p>
              {towerLabel}: <span>{user.tower.name}</span>
            </p>
          )}
          {user.apartment?.name && (
            <p>
              {aptLabel}: <span>{user.apartment.name}</span>
            </p>
          )}
          {user.content && (
            <Message className={classes.join(" ")}>
              Última mensagem: <span>{user.content}</span>
            </Message>
          )}
        </UserTextData>
      </UserInfo>
    </Container>
  );
}
