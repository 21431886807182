import { useRecoilState } from "recoil";
import userImg from "../../assets/imgs/user.png";
import { User } from "../../interfaces/User";
import Avatar from "../Avatar";
import Button from "../Button";
import { ButtonsContainer, Card, UserInfo, UserTextData } from "./styles";
import userState from "../../states/userState";
import { useMemo } from "react";

interface EditUserCardProps {
  user: User;
  size?: string;
  onClick: () => void;
}

export default function EditUserCard({
  user,
  size = "md",
  onClick,
}: EditUserCardProps) {
  const [loggedUser] = useRecoilState(userState);

  const towerLabel =
    loggedUser?.residential?.labelType !== "house" ? "Torre" : "Rua";
  const aptLabel =
    loggedUser?.residential?.labelType === "house"
      ? "Casa"
      : loggedUser?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apt";

  const residentLabel =
    loggedUser?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";

  const fullName = `${user.name ?? ""} ${user.surname ?? ""}`;
  const photo = user.imageUUID
    ? "https://guugcall-prod.s3-sa-east-1.amazonaws.com/users_images/" +
      user.imageUUID +
      ".jpg"
    : userImg;

  const residentTypeLabels = useMemo(
    () => ({
      owner: "Proprietário",
      tenant:
        loggedUser?.residential?.labelType === "commercial"
          ? "Locatário"
          : "Inquilino",
      employee: "Funcionário",
    }),
    [loggedUser?.residential?.labelType]
  );

  return (
    <Card>
      <UserInfo>
        <Avatar
          src={photo}
          alt={`Foto d${
            residentLabel === "Morador" ? "o" : "a"
          } ${residentLabel.toLocaleLowerCase()}`}
          size="sm"
        />
        <UserTextData>
          <h4>{fullName}</h4>
          {user.company && (
            <p>
              Empresa: <span>{user.company}</span>
            </p>
          )}
          {user.residentType && (
            <p>
              <span>{residentTypeLabels[user.residentType]}</span>
            </p>
          )}
          {user.condominium?.name &&
            user.condominium?.name !== user.tower?.name && (
              <p>
                Cond: <span>{user.condominium.name}</span>
              </p>
            )}
          {user.tower?.name && (
            <p>
              {towerLabel}: <span>{user.tower.name}</span>
            </p>
          )}
          {user.apartment?.name && (
            <p>
              {aptLabel}: <span>{user.apartment.name}</span>
            </p>
          )}
        </UserTextData>
      </UserInfo>
      <ButtonsContainer>
        <Button title="Detalhes" size="sm" onClick={onClick} />
      </ButtonsContainer>
    </Card>
  );
}
