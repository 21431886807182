import axios from "axios";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { useRecoilState } from "recoil";
import * as VoxImplant from "voximplant-websdk";
import { ClientState } from "voximplant-websdk";
import Button from "../../components/Button";
import ControlledInput from "../../components/ControlledInput";
import { getFirebaseToken } from "../../firebase";
import {
  AppNotification,
  OpenDoorNotificationData,
} from "../../interfaces/AppNotification";
import { User } from "../../interfaces/User";
import { api } from "../../services/api";
import notificationsState from "../../states/notificationsState";
import userState from "../../states/userState";
import { notify, ToastType } from "../../utils/toast";
import {
  ButtonContainer,
  Container,
  InputsContainer,
  LoginBox,
  PasswordContainer,
  PasswordVisibleButton,
} from "./styles";
import moment from "moment";
import AccessLog from "../../interfaces/AccessLog";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { encrypt } from "../../utils/crypto";

interface Inputs {
  phone: string;
  password: string;
}

interface FormErrors {
  phone: string;
  password: string;
}

export default function Login() {
  const navigate = useNavigate();
  const [, setUser] = useRecoilState(userState);
  const [, setNotifications] = useRecoilState(notificationsState);

  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({} as FormErrors);
  const [isAwaiting, setIsAwaiting] = useState<boolean>(false);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const validateFields = (fields: Inputs) => {
    const { phone, password } = fields;

    const errors: FormErrors = {} as FormErrors;

    if (!phone) errors.phone = "Digite seu número de telefone";
    if (phone.length > 11 && phone.length < 10)
      errors.phone = "Número de telefone inválido";
    if (!password) errors.password = "Digite sua senha";
    setFormErrors(errors);

    const areFieldsValid = Object.values(errors).length <= 0;

    return areFieldsValid;
  };
  const fetchUser = useCallback(
    async (fields: Inputs) => {
      try {
        const response = await api.post("user/login", fields);

        const user = response.data.user ?? response.data;
        const { token } = user;

        if (token) {
          localStorage.setItem("guugwebporter-jwt-token", encrypt(token));
          delete user.token;
        }

        return user;
      } catch (err: any) {
        notify("Usuário e/ou senha incorretos!", ToastType.error);

        setIsAwaiting(false);
      }
    },
    [setIsAwaiting]
  );
  const storeUser = useCallback(
    (user: User) => {
      const data = JSON.stringify(user);
      localStorage.setItem("guugWebPorter.user", data);

      setUser(user);
    },
    [setUser]
  );
  const connectToFirebase = useCallback(
    async (user: User) => {
      try {
        const token = await getFirebaseToken();

        await api.post("wp_notification_manager/subscribe", {
          fcm: token,
          topic: String(user.id),
        });

        if (user.condominiumId)
          await api.post("wp_notification_manager/subscribe", {
            fcm: token,
            topic: `condominium_${user.condominiumId}`,
          });

        if (user.residentialId)
          await api.post("wp_notification_manager/subscribe", {
            fcm: token,
            topic: `residential_${user.residentialId}`,
          });

        if (user.permission === "generalPorter")
          await api.post("wp_notification_manager/subscribe", {
            fcm: token,
            topic: `generalPorter_${user.residentialId}`,
          });
        else if (user.permission === "localPorter")
          await api.post("wp_notification_manager/subscribe", {
            fcm: token,
            topic: `localPorter_${user.condominiumId}`,
          });
      } catch (err) {
        console.log(err);

        setIsAwaiting(false);
      }
    },
    [setIsAwaiting]
  );
  const voxImplantLogin = useCallback(
    async (user: User) => {
      const voxImplantUrl = process.env.REACT_APP_VOXIMPLANT_URL;

      const voxImplantClient = VoxImplant.getInstance();
      const { alreadyInitialized } = voxImplantClient;

      if (!alreadyInitialized) await voxImplantClient.init({});

      await voxImplantClient.connect();

      try {
        await voxImplantClient.login(`${user.phone}${voxImplantUrl}`, "123456");
      } catch (e: any) {
        if (e.code === 404) {
          await axios.post(
            `https://api.voximplant.com/platform_api/AddUser/?account_id=3822088&api_key=1e2f1400-2932-4794-bcef-190da3d09425&user_name=${
              user.phone
            }&user_display_name=${user.name + " "}${
              user.surname
            }&user_password=123456&application_id=10241942`
          );

          await voxImplantClient.login(
            `${user.phone}${voxImplantUrl}`,
            "123456"
          );
        }

        setIsAwaiting(false);
      }
    },
    [setIsAwaiting]
  );
  /* const setInitialNotifications = useCallback(
    async (user: User) => {
      const initialOpenDoorNotifications: AppNotification[] = [];

      if (user.residentialId) {
        const endpoint = `/access_logs/getLastResidential/${user.residentialId}`;

        const res = await api.get(endpoint);

        const data: AccessLog[] = res.data;

        const initial: AppNotification[] = data.reverse().map((item) => {
          const person =
            item.user ?? item.DependentUser ?? item.GuestRegister ?? null;
          const user = item.user
            ? item.user
            : item.DependentUser
            ? item.DependentUser.user
            : item.GuestRegister
            ? item.GuestRegister.user
            : null;
          const userType = item.user
            ? "user"
            : item.DependentUser
            ? "dependent"
            : item.GuestRegister
            ? "dependent"
            : "";

          return {
            createdAt: item.createdAt,
            date: moment(item.createdAt).format("DD/MM/YYYY"),
            time: moment(item.createdAt).format("HH:mm"),
            notification: {
              title: "",
              body: "",
            },
            data: {
              text: `Acesso em ${item.Automation.Door.name}`,
              userType,
              imageUUID: person?.imageUUID ?? "",
              name:
                person?.name + (person?.surname ? " " + person?.surname : ""),
              apartment: user?.apartment?.name ?? "",
              tower: user?.tower?.name ?? "",
              condominium: user?.condominium?.name ?? "",
              residential: user?.residential?.name ?? "",
              door: item.Automation.Door.name,
              time: moment(item.createdAt).format("HH:mm"),
            },
            type: "AccessNotification",
          };
        });
        initialOpenDoorNotifications.push(...initial);
      }

      const notificationsJson =
        localStorage.getItem(`guugWebPorter.notifications.user${user.id}`) ??
        "[]";
      const initialNotifications: AppNotification[] =
        notificationsJson !== "" ? JSON.parse(notificationsJson) : [];

      if (initialOpenDoorNotifications.length > 0) {
        initialNotifications.unshift(...initialOpenDoorNotifications);

        localStorage.setItem(
          `guugWebPorter.notifications.user${user.id}`,
          JSON.stringify(initialNotifications)
        );
      }

      setNotifications(initialNotifications);
    },
    [setNotifications]
  ); */

  const handleLogin = useCallback(
    async (data: Inputs) => {
      try {
        setIsAwaiting(true);

        const phone = data.phone.replace(/[\s()-]/g, "");

        const { password } = data;

        const areFieldsValid = validateFields({ phone, password });

        if (areFieldsValid) {
          const userData = await fetchUser({ phone, password });

          if (userData) {
            if (
              userData.permission === "generalPorter" ||
              userData.permission === "localPorter"
            ) {
              storeUser(userData);
              navigate("/interfone", { replace: true });

              await connectToFirebase(userData);
              await voxImplantLogin(userData);
              // await setInitialNotifications(userData);
            } else
              notify(
                "Este usuário não tem permissão para acessar o sistema!",
                ToastType.error
              );

            setIsAwaiting(false);
          }
        } else setIsAwaiting(false);
      } catch (e) {
        console.log(e);
      }
    },
    [connectToFirebase, fetchUser, navigate, storeUser, voxImplantLogin]
  );

  const maskPhone = useCallback((phone: string) => {
    return phone
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/, "($1) $2")
      .replace(/^(\(\d{2}\) \d{4})(\d)/, "$1-$2")
      .replace(/^(\(\d{2}\) \d{4})(-)(\d)(\d{4})/, "$1$3$2$4")
      .replace(/^(\(\d{2}\) \d{5}-\d{4})\d/, "$1");
  }, []);

  return (
    <>
      <Container>
        <LoginBox>
          <h1>Bem-vindo(a) à GuuG!</h1>
          <h3>Faça login para acessar o Porteiro Web.</h3>
          <form
            onSubmit={async (event) => {
              event.preventDefault();

              await handleLogin({ phone, password });
            }}
          >
            <InputsContainer>
              <ControlledInput
                size="xl"
                label="Telefone"
                id="phone"
                error={formErrors.phone}
                onChange={(event) => setPhone(maskPhone(event.target.value))}
                value={phone}
              />
              <PasswordContainer>
                <ControlledInput
                  size="xl"
                  type={isPasswordVisible ? "text" : "password"}
                  label="Senha"
                  id="password"
                  error={formErrors.password}
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                />
                <PasswordVisibleButton
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  type="button"
                >
                  {isPasswordVisible ? (
                    <FaEyeSlash size={20} color="#ff4f00" />
                  ) : (
                    <FaEye size={20} color="#ff4f00" />
                  )}
                </PasswordVisibleButton>
              </PasswordContainer>
            </InputsContainer>
            <ButtonContainer>
              <Button
                type="submit"
                title={
                  isAwaiting ? (
                    <SyncLoader size={8} color="#ffffff" />
                  ) : (
                    "Entrar"
                  )
                }
                disabled={isAwaiting}
              />
            </ButtonContainer>
          </form>
        </LoginBox>
      </Container>
    </>
  );
}
