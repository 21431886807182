import { useRecoilCallback, useRecoilState, useSetRecoilState } from "recoil";
import * as VoxImplant from "voximplant-websdk";
import currentCallIdState from "../states/currentCallIdState";
import { User } from "../interfaces/User";
import callingUserState from "../states/callingUserState";
import { api } from "../services/api";
import { now } from "moment";
import callIdsState from "../states/callIdsState";
import { useCallback } from "react";
import receivingCallState from "../states/receivingCallState";

const useStartCall = () => {
  const setCallId = useSetRecoilState(currentCallIdState);
  const [callingUser, setCallingUser] = useRecoilState(callingUserState);
  const setReceivingCall = useSetRecoilState(receivingCallState);

  const setCallIds = useRecoilCallback(
    ({ set }) =>
      (value) => {
        set(callIdsState as any, value);
      },
    []
  );
  const getCallIds = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const state = await snapshot.getPromise(callIdsState);

        return state;
      },
    []
  );

  const getCallingUser = useCallback(async (callId: string): Promise<User> => {
    const { number } = VoxImplant.getInstance().getCall(callId).settings;
    const phoneNumber = number.replace(/(.+:)(\d+)(@.+)/, "$2");
    const res = await api.get(`user/searchByPhone/${phoneNumber}`);

    return res.data;
  }, []);

  const cleanCall = useCallback(
    async (id: string) => {
      const callIds = await getCallIds();

      if (callIds.length > 1) {
        const callIdsAux = [...callIds];

        const newCallIds = callIdsAux.filter((callId) => callId !== id);

        setCallIds(newCallIds);

        const newCallId = newCallIds[0];

        const user = await getCallingUser(newCallId);

        setCallingUser(user);

        setReceivingCall(true);
      } else {
        setCallingUser(null);

        setCallIds([]);
        setReceivingCall(false);
      }
    },
    [getCallIds, setCallIds, getCallingUser, setCallingUser, setReceivingCall]
  );

  return async (user: User) => {
    if (!callingUser) {
      setCallingUser(user);

      const loggedUser = JSON.parse(
        localStorage.getItem("guugWebPorter.user") || ""
      );

      const callSettings = {
        number: user.shouldCallVoip ? user.phone : "55" + user.phone,
      };

      const clientState = VoxImplant.getInstance().getClientState();
      if (clientState !== VoxImplant.ClientState.LOGGED_IN) {
        const voxImplantUrl = process.env.REACT_APP_VOXIMPLANT_URL;

        await VoxImplant.getInstance().login(
          `${loggedUser.phone}${voxImplantUrl}`,
          "123456"
        );
      }

      const call = VoxImplant.getInstance().call(callSettings);

      let initialTime = 0;

      const { data } = await api.post("/call_register", {
        idUserTo: user.id,
        idUserFrom: loggedUser.id,
      });

      setCallIds([call.id()]);

      call.on(VoxImplant.CallEvents.Connected, async (e) => {
        initialTime = now();

        await api.put("/call_register", {
          id: data.id,
          isCallAnswered: true,
        });
      });
      call.on(VoxImplant.CallEvents.Disconnected, async (e) => {
        const endTime = now();

        const callTime =
          initialTime > 0
            ? Math.round((endTime - initialTime) / 1000)
            : initialTime;

        await api.put("/call_register", {
          id: data.id,
          callTime,
        });

        await cleanCall(e.call.id());

        call.hangup();
      });
      call.on(VoxImplant.CallEvents.Failed, async (e) => {
        await cleanCall(e.call.id());

        call.hangup();
      });

      setCallId(call.id());
    }
  };
};

export default useStartCall;
