import { AxiosResponse } from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MdCameraAlt, MdDelete } from "react-icons/md";
import { useRecoilState } from "recoil";
import Button from "../../../components/Button";
import ControlledInput from "../../../components/ControlledInput";
import EditUserCard from "../../../components/EditUserCard";
import IntercomComponent from "../../../components/IntercomComponent";
import Layout from "../../../components/Layout";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import WebcamModal from "../../../components/WebcamModal";
import { Apartment } from "../../../interfaces/Apartment";
import { Condominium } from "../../../interfaces/Condominium";
import { Tower } from "../../../interfaces/Tower";
import { Permission, User } from "../../../interfaces/User";
import { api } from "../../../services/api";
import photoValidationState from "../../../states/photoValidationState";
import userState from "../../../states/userState";
import isValidCpf from "../../../utils/isCpfValid";
import { notify, ToastType } from "../../../utils/toast";
import {
  ButtonsContainer,
  CarInputsContainer,
  DeleteButton,
  DeleteButtonContainer,
  FormContainer,
  FormTopButtonsContainer,
  FormTopContainer,
  InputsContainer,
  Main,
  PhotoContainer,
} from "../styles";

type ResidentData = Pick<
  User,
  | "name"
  | "surname"
  | "phone"
  | "email"
  | "cpf"
  | "birthday"
  | "password"
  | "residentialId"
  | "condominiumId"
  | "towerId"
  | "apartmentId"
  | "photo"
  | "residentType"
> & { image: string; confirmPassword: string };
type EditResidentData = ResidentData & { id: number };
type Car = {
  id: number;
  registered: boolean;
  carModel: string;
  carColor: string;
  carPlate: string;
};

type ResidentType = "tenant" | "owner" | "employee";

export default function RegisterResident() {
  const [user] = useRecoilState(userState);

  const apartmentLabel =
    user?.residential?.labelType === "house"
      ? "Casa"
      : user?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apartamento";
  const towerLabel = user?.residential?.labelType !== "house" ? "Torre" : "Rua";
  const residentLabel =
    user?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";

  const [openCamera, setOpenCamera] = useState(false);
  const [isImageVerify, setIsImageVerify] = useState(false);
  const [photoValidation, setPhotoValidation] =
    useRecoilState(photoValidationState);

  const initialResidentData: ResidentData = useMemo(() => {
    return {
      image: "",
      name: "",
      surname: "",
      birthday: "",
      cpf: "",
      email: "",
      phone: "",
      apartmentId: 0,
      towerId: 0,
      condominiumId: 0,
      residentialId: 0,
      password: "",
      confirmPassword: "",
      photo: "",
    };
  }, []);

  const [residentData, setResidentData] =
    useState<ResidentData>(initialResidentData);
  const [editResidentData, setEditResidentData] = useState<EditResidentData>({
    ...initialResidentData,
    id: 0,
  });
  const [editResidentCarsData, setEditResidentCarsData] = useState<{
    userId: number;
    cars: Car[];
  }>({
    userId: 0,
    cars: [
      { id: 0, registered: false, carColor: "", carModel: "", carPlate: "" },
    ],
  });

  const [apartmentsList, setApartmentsList] = useState<Apartment[]>([]);
  const [towersList, setTowersList] = useState<Tower[]>([]);
  const [condominiaList, setCondominiaList] = useState<Condominium[]>([]);

  const [openRegisterFormModal, setOpenRegisterFormModal] = useState(false);
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [vehiclesForm, setVehiclesForm] = useState(false);

  const [forceRefresh, setForceRefresh] = useState(false);

  const [initialPhoneNumber, setInitialPhoneNumber] = useState("");

  const fetchApartments = useCallback(async (towerId: number) => {
    const endpoint = `tower/getApartments/${towerId}`;

    try {
      const response = await api.get(endpoint);

      const apartments: Apartment[] = response.data.apartments;
      const apartmentsOptions = apartments
        .map((apartment) => ({
          id: apartment.id,
          name: apartment.name,
        }))
        .filter((apartment) => apartment.name !== "Interfone");

      return apartmentsOptions;
    } catch (err) {
      console.log(err);
    }
  }, []);
  const fetchTowers = useCallback(async (condominiumId: number) => {
    const endpoint = `condominium/getTowers/${condominiumId}`;

    try {
      const response = await api.get(endpoint);

      const towers: Condominium[] = response.data.towers;
      const condominiumOptions = towers
        .map((tower) => ({
          id: tower.id,
          name: tower.name,
        }))
        .filter((tower) => tower.name !== "Interfone");

      return condominiumOptions;
    } catch (err) {
      console.log(err);
    }
  }, []);
  const fetchCondominia = useCallback(async (residentialId: number) => {
    const endpoint = `residential/getCondominia/${residentialId}`;

    try {
      const response = await api.get(endpoint);

      const condominia: Condominium[] = response.data.condominia;
      const condominiumOptions = condominia
        .map((condominium) => ({
          id: condominium.id,
          name: condominium.name,
        }))
        .filter((condominium) => condominium.name !== "Interfone");

      return condominiumOptions;
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchInitialRegisterGuestResidentSelectOptions =
    useCallback(async () => {
      if (user.permission === "generalPorter") {
        if (user.residentialId) {
          const condominia = await fetchCondominia(user.residentialId);

          if (condominia) setCondominiaList(condominia);
        }
      } else {
        if (user.condominiumId) {
          const towers = await fetchTowers(user.condominiumId);

          if (towers) setTowersList(towers);
        }
      }
    }, [
      fetchCondominia,
      fetchTowers,
      user.condominiumId,
      user.permission,
      user.residentialId,
    ]);

  const getUserFormData = useCallback(
    (data: ResidentData | EditResidentData) => {
      data.cpf = data.cpf?.replace(/[.-]/g, "");
      data.phone = data.phone.replace(/[()-\s]/g, "");
      const formData = new FormData();

      if (data.image) {
        const commaIndex = data.image.indexOf(",");
        if (!data.image.startsWith("https://") && !data.image.endsWith(".jpg"))
          formData.append("fileData", data.image.substring(commaIndex + 1));
      }

      const excludedKeys = [
        "residentialId",
        "condominiumId",
        "towerId",
        "apartmentId",
        "image",
      ];

      Object.entries(data).forEach((item) => {
        if (
          typeof item[0] !== "boolean" &&
          !excludedKeys.includes(item[0]) &&
          item[1]
        )
          formData.append(item[0], String(item[1]));
      });

      formData.append("residential", String(user.residentialId));
      formData.append("apartment", String(data.apartmentId));
      formData.append("tower", String(data.towerId));
      formData.append("permission", "resident");

      if (user.permission === "localPorter")
        formData.append("condominium", String(user.condominiumId));
      else formData.append("condominium", String(data.condominiumId));

      return formData;
    },
    [user.condominiumId, user.permission, user.residentialId]
  );
  const checkFormErrors = useCallback(
    async (data: ResidentData | EditResidentData, type: "create" | "edit") => {
      const errors: string[] = [];

      if (Object.values(data).length === 0)
        errors.push("Preencha os campos do formulário");

      if (!data.image) errors.push("A foto é obrigatória");
      else if (
        !isImageVerify &&
        !data.image.startsWith("https://") &&
        !data.image.endsWith(".jpg")
      )
        errors.push("A foto tem que ser aceita");

      if (user.permission === "generalPorter" && !data.condominiumId)
        errors.push("Condomínio não selecionado");
      if (!data.towerId) errors.push(`${towerLabel} não selecionada`);
      if (!data.apartmentId)
        errors.push(
          `${apartmentLabel} não selecionad${
            apartmentLabel === "Apartamento" ? "o" : "a"
          }`
        );
      if (data.name.length < 2) errors.push("Nome inválido");
      if (!data.surname || (data.surname && data.surname.length < 2))
        errors.push("Sobrenome inválido");
      if ((data as ResidentData).residentType?.length === 0)
        errors.push(
          `Selecione um tipo de ${residentLabel.toLocaleLowerCase()}`
        );
      if (data.cpf && !isValidCpf(data.cpf.replace(/[-.]/g, "")))
        errors.push("CPF inválido");
      if (
        !(
          data.phone.replace(/[-()\s]/g, "").length == 11 ||
          data.phone.replace(/[-()\s]/g, "").length == 10
        )
      )
        errors.push("Número de telefone inválido");

      if (
        type == "create" &&
        (!initialPhoneNumber ||
          initialPhoneNumber.replace(/[-()\s]/g, "") !==
            data.phone.replace(/[-()\s]/g, ""))
      ) {
        const res = await api.post("/user/testPhoneNumber", {
          phone: data.phone.replace(/[-()\s]/g, ""),
        });
        const numberAvailable: boolean = res.data.numberAvailable;
        if (!numberAvailable) errors.push("Número de telefone indisponível!");
      }

      if (data.email && !/[\w.]+@[\w.]+\.[\w.]+/.test(data.email))
        errors.push("E-mail inválido");
      if (!data.birthday) errors.push("Data de nascimento inválida");
      if (data.password && data.password.length < 6)
        errors.push("Senha inválida");
      if (data.password !== data.confirmPassword)
        errors.push("As senhas devem ser iguais");

      if (errors.length > 0) {
        const error = errors.find((error) => error);
        return error;
      }

      return false;
    },
    [
      apartmentLabel,
      initialPhoneNumber,
      isImageVerify,
      residentLabel,
      towerLabel,
      user.permission,
    ]
  );
  const handleUserFormSend = useCallback(
    async (data: EditResidentData | ResidentData, type: "create" | "edit") => {
      let error = await checkFormErrors(data, type);

      if (error) return notify(error, ToastType.error, 3000);

      const formData = getUserFormData(data);
      formData.append("status", "accepted");

      if (type === "edit") {
        if (Object.keys(data).some((key) => key === "id")) {
          const { id } = data as EditResidentData;
          formData.append("id", String(id));
        }
      }

      try {
        let response: AxiosResponse | null = null;
        const config = {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        };

        if (type === "create") {
          response = await api.post("/user/porterWeb", formData, config);

          setForceRefresh(true);
        } else if (type === "edit") {
          response = await api.post("/user/updatePorterWeb", formData, config);

          setForceRefresh(true);
        }

        if (response) {
          if (response.status === 200) {
            setResidentData(initialResidentData);
            setEditResidentData({ ...initialResidentData, id: 0 });

            setTimeout(
              () =>
                notify(
                  type === "create"
                    ? `${residentLabel} cadastrad${
                        residentLabel === "Morador" ? "o" : "a"
                      } com sucesso!`
                    : `Dados d${
                        residentLabel === "Morador" ? "o" : "a"
                      } ${residentLabel.toLocaleLowerCase()} alterados com sucesso!`,
                  ToastType.success,
                  3000
                ),
              500
            );
          }

          if (type === "create") setOpenRegisterFormModal(false);
          else {
            setOpenUserDetailsModal(false);
            setEnableEdit(false);
          }
        }
      } catch (err) {
        console.log(err);

        notify(
          type === "create"
            ? "Ops... Erro ao cadastrar morador!"
            : "Ops... Erro ao editar morador",
          ToastType.error
        );
      }
    },
    [checkFormErrors, getUserFormData, initialResidentData, residentLabel]
  );

  const getRegisterUserFormJSX = useCallback(
    () => (
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleUserFormSend(residentData, "create");
        }}
      >
        <FormTopContainer>
          <PhotoContainer onClick={() => setOpenCamera(true)}>
            {residentData.image && residentData.image.length > 0 ? (
              <img src={residentData.image} alt="" />
            ) : (
              <>
                <MdCameraAlt size={30} />
                <span>Adicionar foto</span>
              </>
            )}
          </PhotoContainer>
        </FormTopContainer>
        <InputsContainer>
          {user.permission === Permission.GeneralPorter && (
            <Select
              id="condominia"
              label="Condomínio"
              value={
                residentData.condominiumId && residentData.condominiumId !== 0
                  ? String(residentData.condominiumId)
                  : ""
              }
              options={condominiaList}
              onChange={(e) =>
                setResidentData({
                  ...residentData,
                  towerId: 0,
                  apartmentId: 0,
                  condominiumId: +e.target.value,
                })
              }
            />
          )}
          <Select
            id="towers"
            label={towerLabel}
            value={
              residentData.towerId && residentData.towerId !== 0
                ? String(residentData.towerId)
                : ""
            }
            options={towersList}
            onChange={(e) =>
              setResidentData({
                ...residentData,
                apartmentId: 0,
                towerId: +e.target.value,
              })
            }
          />
          <Select
            id="apartments"
            label={apartmentLabel}
            value={
              residentData.apartmentId && residentData.apartmentId !== 0
                ? String(residentData.apartmentId)
                : ""
            }
            options={apartmentsList}
            onChange={(e) =>
              setResidentData({
                ...residentData,
                apartmentId: +e.target.value,
              })
            }
          />
          <ControlledInput
            label="Nome"
            value={residentData.name}
            onChange={(e) => {
              setResidentData({
                ...residentData,
                name: e.target.value,
              });
            }}
          />
          <ControlledInput
            label="Sobrenome"
            value={residentData.surname ?? ""}
            onChange={(e) => {
              setResidentData({
                ...residentData,
                surname: e.target.value,
              });
            }}
          />
          <Select
            id="type"
            label="Tipo"
            value={residentData.residentType ?? ""}
            options={
              user?.residential?.labelType === "commercial"
                ? [
                    { id: "employee", name: "Funcionário" },
                    { id: "owner", name: "Proprietário" },
                    { id: "tenant", name: "Locatário" },
                  ]
                : [
                    { id: "owner", name: "Proprietário" },
                    { id: "tenant", name: "Inquilino" },
                  ]
            }
            onChange={(e) =>
              setResidentData({
                ...residentData,
                residentType: e.target.value as ResidentType,
              })
            }
          />
          <ControlledInput
            label="CPF"
            value={residentData.cpf ?? ""}
            onChange={(e) => {
              const masked = e.target.value
                .replace(/(\D)/g, "")
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3}\.\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3}\.\d{3}\.\d{3})(\d)/, "$1-$2")
                .replace(/(\d{3}\.\d{3}\.\d{3}-\d{2})\d/, "$1");

              setResidentData({
                ...residentData,
                cpf: masked,
              });
            }}
          />
          <ControlledInput
            label="Telefone"
            value={residentData.phone}
            onChange={(e) => {
              const masked = e.target.value
                .replace(/\D/g, "")
                .replace(/(\d{2})/, "($1)")
                .replace(/(\(\d{2}\))(\d{4})/, "$1 $2")
                .replace(/(\(\d{2}\) \d{4})(\d)/, "$1-$2")
                .replace(/(\(\d{2}\) \d{4})(-)(\d)(\d{4})/, "$1$3$2$4")
                .replace(/(\(\d{2}\) \d{5}-\d{4})\d/, "$1");

              setResidentData({
                ...residentData,
                phone: masked,
              });
            }}
          />
          <ControlledInput
            label="E-mail"
            value={residentData.email ?? ""}
            onChange={(e) => {
              setResidentData({
                ...residentData,
                email: e.target.value,
              });
            }}
          />
          <ControlledInput
            type="date"
            max={moment().format("YYYY-MM-DD")}
            label="Data da nascimento"
            value={residentData.birthday ?? ""}
            onChange={(e) => {
              setResidentData({
                ...residentData,
                birthday: e.target.value,
              });
            }}
          />
          <ControlledInput
            label="Senha"
            type="password"
            value={residentData.password ?? ""}
            onChange={(e) => {
              setResidentData({
                ...residentData,
                password: e.target.value,
              });
            }}
          />
          <ControlledInput
            label="Confirmar senha"
            type="password"
            value={residentData.confirmPassword ?? ""}
            onChange={(e) => {
              setResidentData({
                ...residentData,
                confirmPassword: e.target.value,
              });
            }}
          />
        </InputsContainer>
        <ButtonsContainer>
          <Button type="submit" title="Cadastrar" />
        </ButtonsContainer>
      </FormContainer>
    ),
    [
      apartmentLabel,
      apartmentsList,
      condominiaList,
      handleUserFormSend,
      residentData,
      towerLabel,
      towersList,
      user.permission,
      user?.residential?.labelType,
    ]
  );

  const getDetailsUserFormJSX = useCallback(
    () => (
      <FormContainer
        style={{ maxHeight: 600, overflow: "auto" }}
        onSubmit={async (e) => {
          e.preventDefault();

          if (vehiclesForm) {
            const { userId } = editResidentCarsData;

            const error = editResidentCarsData.cars.some((car) => {
              if (car.carModel.length < 2) {
                notify(
                  "O modelo do carro deve ter pelo menos 2 caracteres",
                  ToastType.error,
                  3000
                );
                return true;
              } else if (car.carColor.length < 3) {
                notify(
                  "A cor do carro deve ter pelo menos 3 caracteres",
                  ToastType.error,
                  3000
                );
                return true;
              } else if (
                !/[a-zA-Z]{3}\d{4}/.test(car.carPlate) &&
                !/[a-zA-Z]{3}\d[a-zA-Z]\d{2}/.test(car.carPlate)
              ) {
                notify(
                  "A placa de um dos carros possui formato incorreto",
                  ToastType.error,
                  3000
                );
                return true;
              }

              return false;
            });

            if (!error) {
              editResidentCarsData.cars.forEach(async (car) => {
                const { id, carModel, carColor, carPlate } = car;

                if (car.registered) {
                  await api.put("/car_by_user", {
                    id,
                    carModel,
                    carColor,
                    carPlate,
                  });
                } else
                  await api.post("/car_by_user", {
                    userId,
                    carModel,
                    carColor,
                    carPlate,
                  });
              });

              notify(
                "Informações de veículos atualizadas com sucesso!",
                ToastType.success,
                3000
              );
              setOpenUserDetailsModal(false);
            }
          } else {
            handleUserFormSend(editResidentData, "edit");
          }
        }}
      >
        {vehiclesForm ? (
          <>
            <ButtonsContainer
              style={{
                marginTop: 0,
                marginBottom: "1rem",
              }}
            >
              <Button
                size="md"
                title="Novo veículo"
                onClick={() => {
                  const cars =
                    editResidentCarsData.cars.length > 0
                      ? [...editResidentCarsData.cars]
                      : [];
                  cars.push({
                    id: 0,
                    registered: false,
                    carColor: "",
                    carModel: "",
                    carPlate: "",
                  });

                  setEditResidentCarsData({ ...editResidentCarsData, cars });
                }}
              />
              <Button
                size="md"
                title={`Editar ${residentLabel.toLocaleLowerCase()}`}
                onClick={() => {
                  setVehiclesForm(false);
                }}
              />
            </ButtonsContainer>
            <CarInputsContainer>
              {editResidentCarsData.cars.length > 0 &&
                editResidentCarsData.cars.map((car, i, cars) => {
                  return (
                    <React.Fragment key={i}>
                      <ControlledInput
                        label="Modelo"
                        value={car.carModel ?? ""}
                        onChange={(e) => {
                          const newCars = [...cars];

                          newCars.splice(i, 1, {
                            id: car.id,
                            registered: car.registered,
                            carColor: car.carColor,
                            carPlate: car.carPlate,
                            carModel: e.target.value,
                          });

                          setEditResidentCarsData({
                            ...editResidentCarsData,
                            cars: newCars,
                          });
                        }}
                      />
                      <ControlledInput
                        label="Cor"
                        value={car.carColor ?? ""}
                        onChange={(e) => {
                          const newCars = [...cars];

                          newCars.splice(i, 1, {
                            id: car.id,
                            registered: car.registered,
                            carModel: car.carModel,
                            carPlate: car.carPlate,
                            carColor: e.target.value,
                          });

                          setEditResidentCarsData({
                            ...editResidentCarsData,
                            cars: newCars,
                          });
                        }}
                      />
                      <ControlledInput
                        label="Placa"
                        value={car.carPlate ?? ""}
                        onChange={(e) => {
                          const newCars = [...cars];

                          newCars.splice(i, 1, {
                            id: car.id,
                            registered: car.registered,
                            carModel: car.carModel,
                            carColor: car.carColor,
                            carPlate: e.target.value.toUpperCase(),
                          });

                          setEditResidentCarsData({
                            ...editResidentCarsData,
                            cars: newCars,
                          });
                        }}
                      />
                      <DeleteButtonContainer>
                        <DeleteButton
                          onClick={(e) => {
                            if (car.id !== 0) {
                              api
                                .delete(`car_by_user/${car.id}`)
                                .then(() =>
                                  notify(
                                    "Veículo removido com sucesso",
                                    ToastType.success,
                                    3000
                                  )
                                )
                                .catch((err) => console.log(err));
                            }

                            const newCars = [...cars];

                            newCars.splice(i, 1);

                            setEditResidentCarsData({
                              ...editResidentCarsData,
                              cars: newCars,
                            });
                          }}
                          type="button"
                        >
                          <MdDelete size={25} color="#fff" />
                        </DeleteButton>
                      </DeleteButtonContainer>
                    </React.Fragment>
                  );
                })}
            </CarInputsContainer>
          </>
        ) : (
          <>
            <FormTopContainer>
              <PhotoContainer
                className={!enableEdit ? "disabled" : ""}
                onClick={() => {
                  if (enableEdit) setOpenCamera(true);
                }}
              >
                {editResidentData.image && editResidentData.image.length > 0 ? (
                  <img src={editResidentData.image} alt="" />
                ) : (
                  <>
                    <MdCameraAlt size={30} />
                    <span>Adicionar foto</span>
                  </>
                )}
              </PhotoContainer>
              <FormTopButtonsContainer
                style={{
                  marginTop: 0,
                  marginBottom: "1rem",
                }}
              >
                <Button
                  size="md"
                  title="Editar"
                  onClick={() => setEnableEdit(!enableEdit)}
                />
                <Button
                  size="md"
                  title="Adicionar veículos"
                  onClick={() => setVehiclesForm(true)}
                />
              </FormTopButtonsContainer>
            </FormTopContainer>
            <InputsContainer>
              {user.permission === Permission.GeneralPorter && (
                <Select
                  disabled
                  id="condominia"
                  label="Condomínio"
                  value={String(
                    editResidentData.condominiumId !== 0
                      ? editResidentData.condominiumId
                      : ""
                  )}
                  options={condominiaList}
                  onChange={(e) =>
                    setEditResidentData({
                      ...editResidentData,
                      condominiumId: +e.target.value,
                    })
                  }
                />
              )}
              <Select
                disabled
                id="towers"
                label={towerLabel}
                value={String(
                  editResidentData.towerId !== 0 ? editResidentData.towerId : ""
                )}
                options={towersList}
                onChange={(e) =>
                  setEditResidentData({
                    ...editResidentData,
                    towerId: +e.target.value,
                  })
                }
              />
              <Select
                disabled
                id="apartments"
                label={apartmentLabel}
                value={String(
                  editResidentData.apartmentId !== 0
                    ? editResidentData.apartmentId
                    : ""
                )}
                options={apartmentsList}
                onChange={(e) =>
                  setEditResidentData({
                    ...editResidentData,
                    apartmentId: +e.target.value,
                  })
                }
              />
              <ControlledInput
                disabled={!enableEdit}
                label="Nome"
                id="Nome"
                value={editResidentData.name}
                onChange={(e) => {
                  setEditResidentData({
                    ...editResidentData,
                    name: e.target.value,
                  });
                }}
              />
              <ControlledInput
                disabled={!enableEdit}
                label="Sobrenome"
                id="Sobrenome"
                value={editResidentData.surname ?? ""}
                onChange={(e) => {
                  setEditResidentData({
                    ...editResidentData,
                    surname: e.target.value,
                  });
                }}
              />
              <Select
                disabled={!enableEdit}
                id="type"
                label="Tipo"
                value={editResidentData.residentType ?? ""}
                options={
                  user?.residential?.labelType === "commercial"
                    ? [
                        { id: "employee", name: "Funcionário" },
                        { id: "owner", name: "Proprietário" },
                        { id: "tenant", name: "Locatário" },
                      ]
                    : [
                        { id: "owner", name: "Proprietário" },
                        { id: "tenant", name: "Inquilino" },
                      ]
                }
                onChange={(e) =>
                  setEditResidentData({
                    ...editResidentData,
                    residentType: e.target.value as ResidentType,
                  })
                }
              />
              <ControlledInput
                disabled={!enableEdit}
                label="CPF"
                id="CPF"
                value={editResidentData.cpf ?? ""}
                onChange={(e) => {
                  const masked = e.target.value
                    .replace(/(\D)/g, "")
                    .replace(/(\d{3})(\d)/, "$1.$2")
                    .replace(/(\d{3}\.\d{3})(\d)/, "$1.$2")
                    .replace(/(\d{3}\.\d{3}\.\d{3})(\d)/, "$1-$2")
                    .replace(/(\d{3}\.\d{3}\.\d{3}-\d{2})\d/, "$1");

                  setEditResidentData({
                    ...editResidentData,
                    cpf: masked,
                  });
                }}
              />
              <ControlledInput
                disabled
                label="Telefone"
                id="Telefone"
                value={editResidentData.phone}
                onChange={(e) => {
                  const masked = e.target.value
                    .replace(/\D/g, "")
                    .replace(/(\d{2})/, "($1)")
                    .replace(/(\(\d{2}\))(\d{4})/, "$1 $2")
                    .replace(/(\(\d{2}\) \d{4})(\d)/, "$1-$2")
                    .replace(/(\(\d{2}\) \d{4})(-)(\d)(\d{4})/, "$1$3$2$4");

                  setEditResidentData({
                    ...editResidentData,
                    phone: masked,
                  });
                }}
              />
              <ControlledInput
                disabled={!enableEdit}
                label="E-mail"
                id="E-mail"
                value={editResidentData.email ?? ""}
                onChange={(e) => {
                  setEditResidentData({
                    ...editResidentData,
                    email: e.target.value,
                  });
                }}
              />
              <ControlledInput
                disabled={!enableEdit}
                type="date"
                max={moment().format("YYYY-MM-DD")}
                label="Data da nascimento"
                id="Data da nascimento"
                value={editResidentData.birthday ?? ""}
                onChange={(e) => {
                  setEditResidentData({
                    ...editResidentData,
                    birthday: e.target.value,
                  });
                }}
              />
              <ControlledInput
                disabled={!enableEdit}
                label="Senha"
                id="Senha"
                type="password"
                value={editResidentData.password ?? ""}
                onChange={(e) => {
                  setEditResidentData({
                    ...editResidentData,
                    password: e.target.value,
                  });
                }}
              />
              <ControlledInput
                disabled={!enableEdit}
                label="Confirmar senha"
                id="Confirmar senha"
                type="password"
                value={editResidentData.confirmPassword ?? ""}
                onChange={(e) => {
                  setEditResidentData({
                    ...editResidentData,
                    confirmPassword: e.target.value,
                  });
                }}
              />
            </InputsContainer>
          </>
        )}

        <ButtonsContainer>
          <Button
            disabled={!enableEdit && !vehiclesForm}
            type="submit"
            title="Salvar"
          />
        </ButtonsContainer>
      </FormContainer>
    ),
    [
      apartmentLabel,
      apartmentsList,
      condominiaList,
      editResidentCarsData,
      editResidentData,
      enableEdit,
      handleUserFormSend,
      residentLabel,
      towerLabel,
      towersList,
      user.permission,
      user?.residential?.labelType,
      vehiclesForm,
    ]
  );

  const handleDetailUser = useCallback(async (resident: User) => {
    const response = await api.get(`/car_by_user/byUser/${resident.id}`);
    const carsData = response.data;

    setEditResidentData({
      id: resident.id,
      image: resident.imageUUID
        ? "https://guugcall-prod.s3-sa-east-1.amazonaws.com/users_images/" +
          resident.imageUUID +
          ".jpg"
        : "",
      name: resident.name,
      surname: resident.surname,
      phone: resident.phone,
      residentialId: resident.residentialId,
      condominiumId: resident.condominiumId,
      towerId: resident.towerId,
      apartmentId: resident.apartmentId,
      birthday: resident.birthday,
      password: "",
      confirmPassword: "",
      cpf: resident.cpf,
      email: resident.email,
      residentType: resident.residentType,
    });

    if (carsData.length > 0) {
      const cars = carsData.map((car: Car) => ({ ...car, registered: true }));
      setEditResidentCarsData({
        userId: resident.id,
        cars,
      });
    } else
      setEditResidentCarsData({
        userId: resident.id,
        cars: [
          {
            id: 0,
            carColor: "",
            carModel: "",
            carPlate: "",
            registered: false,
          },
        ],
      });

    setOpenUserDetailsModal(true);
  }, []);

  const handleFetchTowersList = useCallback(
    async (condominiumId: number) => {
      const towers = await fetchTowers(condominiumId);

      if (towers) setTowersList(towers);
    },
    [fetchTowers]
  );
  const handleFetchApartmentsList = useCallback(
    async (towerId: number) => {
      const apartments = await fetchApartments(towerId);

      if (apartments) setApartmentsList(apartments);
    },
    [fetchApartments]
  );

  useEffect(() => {
    setInitialPhoneNumber(editResidentData.phone);
  }, [editResidentData.phone]);

  useEffect(() => {
    if (editResidentData.towerId && editResidentData.towerId !== 0)
      handleFetchApartmentsList(editResidentData.towerId);
    else {
      setEditResidentData({ ...editResidentData, apartmentId: 0 });
      setApartmentsList([]);
    }
  }, [handleFetchApartmentsList, editResidentData.towerId]);
  useEffect(() => {
    if (residentData.towerId && residentData.towerId !== 0)
      handleFetchApartmentsList(residentData.towerId);
    else {
      setResidentData({ ...residentData, apartmentId: 0 });
      setApartmentsList([]);
    }
  }, [handleFetchApartmentsList, residentData.towerId]);
  useEffect(() => {
    if (editResidentData.condominiumId && editResidentData.condominiumId !== 0)
      handleFetchTowersList(editResidentData.condominiumId);
    else {
      setEditResidentData({ ...editResidentData, towerId: 0, apartmentId: 0 });
      setApartmentsList([]);
      setTowersList([]);
    }
  }, [handleFetchTowersList, editResidentData.condominiumId]);
  useEffect(() => {
    if (residentData.condominiumId && residentData.condominiumId !== 0)
      handleFetchTowersList(residentData.condominiumId);
    else {
      setResidentData({ ...residentData, towerId: 0, apartmentId: 0 });
      setApartmentsList([]);
      setTowersList([]);
    }
  }, [handleFetchTowersList, residentData.condominiumId]);

  useEffect(() => {
    fetchInitialRegisterGuestResidentSelectOptions();
  }, [fetchInitialRegisterGuestResidentSelectOptions]);

  useEffect(() => {
    if (photoValidation.isValid == "true") {
      notify("Foto validada com sucesso!", ToastType.success);
      setIsImageVerify(true);
    } else if (photoValidation.isValid == "false") {
      notify("Foto inválida: Tire outra foto!", ToastType.error);
      setEditResidentData((prev) => ({ ...prev, image: "" }));
    } else if (photoValidation.isValid == "noPhoto") {
      return;
    }
    setPhotoValidation({ isValid: "noPhoto" });
  }, [photoValidation.isValid]);

  useEffect(() => {
    if (!openRegisterFormModal) setResidentData({} as ResidentData);
  }, [openRegisterFormModal]);

  return (
    <Layout>
      <Modal
        title={`Cadastrar nov${
          residentLabel === "Morador" ? "o" : "a"
        } ${residentLabel.toLocaleLowerCase()}`}
        active={openRegisterFormModal}
        content={getRegisterUserFormJSX()}
        onClose={() => setOpenRegisterFormModal(false)}
      />

      <Modal
        title={`Detalhes d${
          residentLabel === "Morador" ? "o" : "a"
        } ${residentLabel.toLocaleLowerCase()}`}
        active={openUserDetailsModal}
        content={getDetailsUserFormJSX()}
        onClose={() => setOpenUserDetailsModal(false)}
      />

      {openCamera && (
        <WebcamModal
          style={{ display: openCamera ? "flex" : "none" }}
          shouldVerifyUserImage={Boolean(
            user.residential?.shouldVerifyUserImage
          )}
          setUserImage={(img) => {
            setIsImageVerify(false);
            if (openRegisterFormModal)
              setResidentData({ ...residentData, image: img });
            else if (openUserDetailsModal)
              setEditResidentData({ ...editResidentData, image: img });
          }}
          setOpen={setOpenCamera}
        />
      )}
      <Main>
        <div style={{ marginTop: "1rem", width: "100%" }}>
          <IntercomComponent
            forceRefreshState={[forceRefresh, setForceRefresh]}
            onlyResidents
            hasAnotherButton
            buttonAction={() => setOpenRegisterFormModal(true)}
            buttonLabel="Adicionar"
            Card={EditUserCard}
            cardButtonAction={handleDetailUser}
          />
        </div>
      </Main>
    </Layout>
  );
}
