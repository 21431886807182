import axios from "axios";
import { decrypt } from "../utils/crypto";

const envType = process.env.REACT_APP_NODE_ENV;

const baseURL =
  envType === "dev"
    ? "https://dev.guug.com.br"
    : envType === "prod"
    ? "https://apiv1.guug.com.br"
    : envType === "local"
    ? "https://localdev.guug.com.br"
    : "";

const token = decrypt(localStorage.getItem("guugwebporter-jwt-token") ?? "");

export const api = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${token}` },
});
