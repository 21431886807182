import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  MdCall,
  MdCallEnd,
  MdOutlineMinimize,
  MdPhoneInTalk,
  MdVolumeOff,
} from "react-icons/md";
import noPhoto from "../../assets/imgs/user.png";
import { User } from "../../interfaces/User";
import { SelectedUserToChat } from "../../states/selectedUserToChatState";
import {
  CallButton,
  CallModalInfo,
  Case,
  CaseContainer,
  CaseOnCallLabel,
  HeadButton,
  HeadButtons,
  Modal,
  ModalBody,
  ModalFoot,
  ModalHead,
  Overlay,
} from "./styles";
import userState from "../../states/userState";
import { useRecoilState } from "recoil";

interface CallModalProps {
  receivingCall: boolean;
  callingUser: User | SelectedUserToChat;
  answer: () => void;
  decline: () => void;
  hangUp: () => void;
  mute: (mute: boolean) => void;
  volumeUp: () => void;
}

export default function CallModal({
  receivingCall,
  callingUser,
  answer,
  decline,
  hangUp,
  mute,
  volumeUp,
}: CallModalProps) {
  const [user] = useRecoilState(userState);

  const towerLabel = user?.residential?.labelType !== "house" ? "Torre" : "Rua";
  const aptLabel =
    user?.residential?.labelType === "house"
      ? "Casa"
      : user?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apt";
  const residentLabel =
    user?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";

  const name = callingUser
    ? callingUser.name + (callingUser.surname ? " " + callingUser.surname : "")
    : null;

  const userName = `${name ?? residentLabel}`;

  const userPhoto =
    callingUser && callingUser.imageUUID
      ? "https://guugcall-prod.s3-sa-east-1.amazonaws.com/users_images/" +
        callingUser.imageUUID +
        ".jpg"
      : noPhoto;

  const [animation, setAnimation] = useState<"minimize" | "expand" | "">("");
  const [overlayCover, setOverlayCover] = useState<boolean>(true);

  const [isMute, setIsMute] = useState(false);

  const animationVariants = {
    minimize: {
      x: "-50vw",
      y: "50vh",
      scale: 0.1,
      opacity: 0,
    },
    expand: {
      x: "100%",
      y: "100%",
      scale: 1,
      opacity: 1,
    },
  };

  /* useEffect(() => {
    if (!active) {
      setIsMute(false);
      setOverlayCover(true);
      setAnimation("");
    }
  }, [active]); */

  useEffect(() => mute(isMute), [isMute]);

  return (
    <Overlay className={overlayCover ? "cover" : ""}>
      <motion.div
        initial={{ x: "100%", y: "100%" }}
        variants={animationVariants}
        transition={{ duration: 0.5 }}
        animate={animation}
      >
        <Modal>
          <ModalHead>
            <h3>{userName}</h3>
            <HeadButtons>
              <HeadButton
                onClick={() => {
                  //setOverlayCover(false);
                  setAnimation("minimize");
                  setTimeout(() => setOverlayCover(false), 500);
                  //setMinimizing(true);
                  /* setTimeout(() => {
                      setMinimizing(false);
                      setMinimized(true);
                    }, 300); */
                }}
              >
                <MdOutlineMinimize />
              </HeadButton>
              {/* <HeadButton>
                <MdOutlineClose />
              </HeadButton> */}
            </HeadButtons>
          </ModalHead>
          <ModalBody>
            <img src={userPhoto} alt="" />
            <CallModalInfo>
              {callingUser && callingUser.condominium && (
                <p>
                  <b>Cond:</b> {callingUser.condominium.name}
                </p>
              )}
              {callingUser && callingUser.tower && (
                <p>
                  <b>{towerLabel}:</b> {callingUser.tower.name}
                </p>
              )}
              {callingUser && callingUser.apartment && (
                <p>
                  <b>{aptLabel}:</b> {callingUser.apartment.name}
                </p>
              )}
            </CallModalInfo>
          </ModalBody>
          <ModalFoot>
            {receivingCall && (
              <CallButton onClick={answer} className="answer-call">
                <MdCall />
              </CallButton>
            )}
            <CallButton
              onClick={receivingCall ? decline : hangUp}
              className="end-call"
            >
              <MdCallEnd />
            </CallButton>
            <CallButton
              onClick={() => setIsMute(!isMute)}
              className={isMute ? "mute-on" : "mute"}
            >
              <MdVolumeOff />
            </CallButton>
          </ModalFoot>
        </Modal>
      </motion.div>
      <CaseContainer
        onClick={() => {
          if (animation === "minimize") {
            setOverlayCover(true);
            setAnimation("expand");
          } else {
            setAnimation("minimize");
            setTimeout(() => setOverlayCover(false), 500);
          }
        }}
        className={!overlayCover ? "minimized" : ""}
      >
        <Case>
          <MdPhoneInTalk />
        </Case>
        {!overlayCover && <CaseOnCallLabel>Em ligação</CaseOnCallLabel>}
      </CaseContainer>
    </Overlay>
  );
}
