import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 100%;
  gap: 2rem;
`;

export const UserCardsContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const UserCards = styled.div`
  margin-top: 1rem;
  flex: 1;
  overflow: auto;

  & > div:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;

export const ScrollLoadingContainer = styled.div`
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
`;

export const ChatContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const ChatHead = styled.div`
  display: flex;
  background-color: var(--orange-500);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: 1.25rem;
  }

  button {
    border: none;
    background-color: #0000;
    transition: transform 0.1s;

    &:active:not([disabled]) {
      transform: scale(0.93, 0.93);
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

export const ChatBody = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  background-color: var(--white);
  padding: 1rem;

  & > div {
    margin-bottom: 0.5rem;
  }
`;

export const ChatFoot = styled.div`
  background-color: var(--orange-500);
  padding: 1rem;

  form {
    display: flex;

    & > div {
      flex: 1;
      margin-right: 0.5rem;
    }
  }

  button {
    background-color: #0000;
    border: none;
    transition: transform 0.1s;

    &:active:not([disabled]) {
      transform: scale(0.93, 0.93);
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
