import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import userImg from "../../assets/imgs/user.png";
import { User } from "../../interfaces/User";
import selectedUserToChatState from "../../states/selectedUserToChatState";
import userState from "../../states/userState";
import useStartCall from "../../utils/startCall";
import { ToastType, notify } from "../../utils/toast";
import Avatar from "../Avatar";
import Button from "../Button";
import { ButtonsContainer, Card, UserInfo, UserTextData } from "./styles";

interface UserCardProps {
  user: User & { company?: string };
  size?: string;
  isEmployee?: boolean;
  isDependent?: boolean;
}

export default function UserCard({
  user,
  size = "md",
  isEmployee = false,
  isDependent,
}: UserCardProps) {
  const [loggedUser] = useRecoilState(userState);

  const [isLoadingCall, setIsLoadingCall] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);

  const startCall = useStartCall();

  const residentLabel =
    loggedUser?.residential?.labelType !== "commercial"
      ? "Morador"
      : "Pessoa interna";
  const aptLabel =
    loggedUser?.residential?.labelType === "house"
      ? "Casa"
      : loggedUser?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apt";
  const towerLabel =
    loggedUser?.residential?.labelType !== "house" ? "Torre" : "Rua";

  const residentTypeLabels = useMemo(
    () => ({
      owner: "Proprietário",
      tenant:
        loggedUser?.residential?.labelType === "commercial"
          ? "Locatário"
          : "Inquilino",
      employee: "Funcionário",
    }),
    [loggedUser?.residential?.labelType]
  );

  const [, setSelectedUserToChat] = useRecoilState(selectedUserToChatState);

  const navigate = useNavigate();

  const fullName = `${user.name ?? ""} ${user.surname ?? ""}`;
  const photo = user.imageUUID
    ? "https://guugcall-prod.s3-sa-east-1.amazonaws.com/users_images/" +
      user.imageUUID +
      ".jpg"
    : userImg;

  const employeeClass = isEmployee ? "employee" : "";

  const callUser = async () => {
    if (user.phone !== "") await startCall(user);
  };

  const messageUser = () => {
    setIsLoadingMessages(true);

    try {
      setSelectedUserToChat(user);

      navigate("/mensagens");
    } catch (e) {
      notify(
        "Ops... Erro aos navegar para a tela de mensagens!",
        ToastType.error
      );
    } finally {
      setIsLoadingMessages(false);
    }
  };

  return (
    <Card className={employeeClass}>
      <UserInfo>
        <Avatar
          src={photo}
          alt={`Foto d${
            residentLabel === "Morador" ? "o" : "a"
          } ${residentLabel.toLocaleLowerCase()}`}
          size="sm"
        />
        <UserTextData>
          <h4>{fullName}</h4>
          {isDependent && (
            <p>
              <span>Dependente</span>
            </p>
          )}
          {user.residentType && (
            <p>
              <span>{residentTypeLabels[user.residentType]}</span>
            </p>
          )}
          {user.company && (
            <p>
              Empresa: <span>{user.company}</span>
            </p>
          )}
          {user.condominium?.name &&
            user.condominium?.name !== user.tower?.name && (
              <p>
                Cond: <span>{user.condominium.name}</span>
              </p>
            )}
          {user.tower?.name && (
            <p>
              {towerLabel}: <span>{user.tower.name}</span>
            </p>
          )}
          {user.apartment?.name && (
            <p>
              {aptLabel}: <span>{user.apartment.name}</span>
            </p>
          )}
        </UserTextData>
      </UserInfo>

      <ButtonsContainer>
        {user.phone && (
          <Button
            title="Ligar"
            size="sm"
            onClick={async () => {
              setIsLoadingCall(true);

              try {
                await callUser();
              } catch {
                notify("Ops... Erro ao iniciar chamada!", ToastType.error);
              } finally {
                setIsLoadingCall(false);
              }
            }}
            loading={isLoadingCall}
          />
        )}
        {!isDependent && (
          <Button
            title="Mensagem"
            isLink
            href="/mensagens"
            size="sm"
            onClick={messageUser}
            loading={isLoadingMessages}
          />
        )}
      </ButtonsContainer>
    </Card>
  );
}
