import moment from "moment";
import { useRecoilValue } from "recoil";
import userPng from "../../../assets/imgs/user.png";
import {
  AccessNotificationData,
  AppNotification,
  CallNotificationData,
  EmergencyNotificationData,
  MessageNotificationData,
  OpenDoorNotificationData,
} from "../../../interfaces/AppNotification";
import userState from "../../../states/userState";
import {
  Container,
  Content,
  ContentContainer,
  Image,
  Info,
  TimeContainer,
  Title,
} from "./styles";
import carSvg from "../../../assets/imgs/car-solid.svg";

interface NotificationProps {
  notificationContent:
    | AccessNotificationData
    | OpenDoorNotificationData
    | MessageNotificationData
    | CallNotificationData
    | EmergencyNotificationData;
  notificationData: AppNotification;
  clickable?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default function Notification({
  notificationContent,
  notificationData,
  clickable = false,
  onClick,
}: NotificationProps) {
  const user = useRecoilValue(userState);

  const aptLabel =
    user?.residential?.labelType === "house"
      ? "Casa"
      : user?.residential?.labelType === "commercial"
      ? "Sala"
      : "Apt";

  const classes = [clickable ? "clickable" : ""].join(" ");

  const today = moment().format("DD/MM/YYYY");
  const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");

  const dateLabel =
    notificationData.date === today
      ? "Hoje"
      : notificationData.date === yesterday
      ? "Ontem"
      : notificationData.date;

  const type =
    notificationData.type === "AccessNotification" ||
    notificationData.type === "OpenDoorNotification" ||
    notificationData.type === "VehicleNotification"
      ? "success"
      : notificationData.type === "CallNotification"
      ? "warn"
      : notificationData.type === "EmergencyNotification"
      ? "error"
      : "info";

  const userImageType =
    notificationData.type === "AccessNotification" &&
    notificationData.data.userType === "guest"
      ? "guests_images"
      : "users_images";
  const image = `https://guugcall-prod.s3-sa-east-1.amazonaws.com/${userImageType}/${notificationContent.imageUUID}.jpg`;

  return (
    <Container className={classes} onClick={onClick}>
      <Image
        src={
          notificationData.type === "VehicleNotification"
            ? carSvg
            : notificationContent.imageUUID
            ? image
            : userPng
        }
      />
      <Info>
        <Title>{`${notificationContent.name?.replace(
          /(.{20})(.+)/,
          "$1..."
        )}`}</Title>
        {notificationData.type === "AccessNotification" &&
          notificationData.data.userType !== "user" && (
            <p>
              {notificationData.data.userType === "dependent"
                ? "Dependente"
                : "Visitante"}
            </p>
          )}
        <p>
          {notificationContent.tower &&
            notificationContent.apartment &&
            `Torre: ${notificationContent.tower} - ${aptLabel}: ${notificationContent.apartment}`}
        </p>
        <ContentContainer type={type}>
          <Content type={type}>{`${notificationContent.text?.replace(
            /(.{40})(.+)/,
            "$1..."
          )}`}</Content>
        </ContentContainer>
      </Info>
      <TimeContainer>
        <time dateTime={notificationData.createdAt}>
          {dateLabel} - {notificationData.time}
        </time>
      </TimeContainer>
    </Container>
  );
}
